<template>
  <div
    id="app"
    :class="{'container has-boxed-layout':isLayoutBoxed}"
  >
    <nav-bar />
    <aside-menu
      v-if="$auth.isAuthenticated"
      :menu="menu"
      :class="{'has-secondary':!!menuSecondary}"
      @menu-click="menuClick"
    />
    <router-view />
    <aside-right />
    <footer-bar />
    <overlay @overlay-click="overlayClick" />
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import { useStore } from '@/store'
import { useRouter } from '@/router'
import NavBar from '@/components/NavBar.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import FooterBar from '@/components/FooterBar.vue'
import Overlay from '@/components/Overlay.vue'
import AsideRight from '@/components/AsideRight.vue'
import ClientService from '@/services/clients.service'
import TeacherService from '@/services/teachers.service'
import { getInstance } from '@/auth/index'

export default {
  name: 'App',
  components: {
    AsideRight,
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar
  },
  setup (props, { root: { $buefy } }) {
    const menuSecondary = ref(null)
    const menuSecondaryLabel = ref(null)
    const menuSecondaryIcon = ref(null)
    const store = useStore()

    const authService = getInstance()
    // do something when the loading state changes
    authService.$watch('loading', (isLoading) => {
      if (isLoading === false) {
        let role
        authService.getTokenSilently().then((response) => {
          authService.getIdTokenClaims().then((claims) => {
            role = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
            store.commit('user', {
              name: authService.user.name,
              email: authService.user.email,
              role: role
            })

            const getCustomerInfoByEmail = async (email) => {
              const { getCustomerByEmail } = ClientService
              const response = await getCustomerByEmail(email)
              const { data } = response
              return data
            }

            const getTeacherInfoByEmail = async (email) => {
              try {
                const { getTeacherByEmail } = TeacherService
                const response = await getTeacherByEmail(email)
                const { data } = response
                return data
              } catch (err) {
              }
            }

            if (store.state.isClient) {
              getCustomerInfoByEmail(authService.user.email).then((response) => {
                if (response !== undefined) {
                  store.commit('user', {
                    clientId: response.id
                  })
                }
              })
            }
            if (store.state.isTeacher) {
              getTeacherInfoByEmail(authService.user.email).then((response) => {
                if (response) {
                  store.commit('user', {
                    teacherId: response.id
                  })
                }
              })
            }
          })
        })
      }
    })

    const menu = computed(() => {
      return [
        [
          {
            to: '/',
            icon: 'desktop-mac',
            label: 'Centralizator',
            visible: true
          }
        ],
        [
          {
            to: '/clients',
            label: 'Clienti',
            icon: 'account-multiple',
            visible: store.state.isCoordinator
          },
          {
            to: '/modules',
            label: 'Module',
            icon: 'calendar',
            visible: store.state.isCoordinator
          },
          {
            to: '/groups',
            label: 'Grupe',
            icon: 'account-group',
            visible: store.state.isCoordinator
          },
          {
            to: '/teachers',
            label: 'Profesori',
            icon: 'human-male-board',
            visible: store.state.isCoordinator
          },
          {
            to: '/students',
            label: 'Cursanti',
            icon: 'account-school',
            visible: store.state.isCoordinator
          },
          // {
          //   to: '/attendance',
          //   label: 'Prezente',
          //   icon: 'table',
          //   visible: store.state.isCoordinator
          // },
          {
            to: '/reports',
            label: 'Rapoarte',
            icon: 'chart-box',
            visible: store.state.isCoordinator
          }
        ]
      ]
    })

    const router = useRouter()

    router.afterEach(() => {
      menuSecondary.value = null
      menuSecondaryLabel.value = null
      menuSecondaryIcon.value = null
    })

    const menuClick = item => {
      if (item.menuSecondary) {
        menuSecondary.value = item.menuSecondary
        menuSecondaryLabel.value = item.menuSecondaryLabel ?? null
        menuSecondaryIcon.value = item.menuSecondaryIcon ?? null

        store.commit('asideActiveForcedKeyToggle', item)
        store.commit('overlayToggle', true)
      } else if (item.action && item.action === 'logout') {
        $buefy.toast.open({
          message: 'Log out clicked',
          type: 'is-danger',
          queue: false
        })
      }
    }

    const menuSecondaryClose = () => {
      menuSecondary.value = menuSecondaryLabel.value = menuSecondaryIcon.value = null

      store.commit('asideActiveForcedKeyToggle', null)

      if (!isLayoutAsideHidden.value) {
        store.commit('overlayToggle', false)
      }
    }

    const overlayClick = () => {
      if (menuSecondary.value) {
        menuSecondaryClose()
      } else {
        store.dispatch('asideCloseAll')
      }
    }
    const isLayoutBoxed = computed(() => store.state.isLayoutBoxed)
    const isLayoutAsideHidden = computed(() => store.state.isLayoutAsideHidden)
    const isLayoutMobile = computed(() => store.state.isLayoutMobile)
    watch(isLayoutMobile, newVal => {
      if (newVal) {
        store.commit('layoutBoxedToggle', false)
      }
    })

    onMounted(() => {
      document.documentElement.classList.remove('has-spinner-active')
    })

    return {
      menu,
      menuSecondary,
      menuSecondaryLabel,
      menuSecondaryIcon,
      menuClick,
      menuSecondaryClose,
      overlayClick,
      isLayoutBoxed
    }
  }
}
</script>
