<template>
  <li
    v-if="item.visible"
    :class="{'is-active':isDropdownActive}"
  >
    <component
      :is="componentIs"
      :to="item.to"
      :href="item.href"
      :target="item.target"
      :title="componentTitle"
      :exact-active-class="componentActiveClass"
      :class="componentClass"
      @click="menuClick"
    >
      <b-icon
        v-if="item.icon"
        :icon="item.icon"
        :class="{ 'has-update-mark' : item.updateMark }"
        custom-size="default"
      />
      <span
        v-if="item.label"
        :class="{'menu-item-label':!!item.icon}"
      >{{ item.label }}</span>
      <div
        v-if="hasSubmenuIcon"
        class="submenu-icon"
      >
        <b-icon
          :icon="submenuIcon"
          custom-size="default"
        />
      </div>
    </component>
    <aside-menu-list
      v-if="hasDropdown"
      :menu="item.menu"
      is-submenu-list
    />
  </li>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { useStore } from '@/store'

export default {
  name: 'AsideMenuItem',
  components: {
    AsideMenuList: () => import('@/components/AsideMenuList.vue')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isSecondary: Boolean
  },
  emits: ['menu-click'],
  setup (props, { emit }) {
    const componentIs = computed(() => props.item.to ? 'router-link' : 'a')

    const isDropdownActive = ref(false)

    const hasDropdown = computed(() => !!props.item.menu)

    const dropdownIcon = computed(() => isDropdownActive.value ? 'minus' : 'plus')

    const hasSubmenuIcon = computed(() => hasDropdown.value || props.item.menuSecondary)

    const submenuIcon = computed(() => {
      if (props.item.menuSecondary) {
        return 'chevron-right'
      }
      return isDropdownActive.value ? 'minus' : 'plus'
    })

    const store = useStore()

    const isAsideExpanded = computed(() => store.state.isAsideExpanded)

    const asideActiveForcedKey = computed(() => store.state.asideActiveForcedKey)

    const componentTitle = computed(() => !isAsideExpanded.value && props.item.label ? props.item.label : null)

    const componentClass = computed(() => {
      const base = []

      if (props.item.icon) {
        base.push('has-icon')
      }

      if (hasSubmenuIcon.value) {
        base.push('has-submenu-icon')
      }

      if (props.item.state) {
        base.push(`is-state-${props.item.state}`, 'is-hoverable')
      }

      if (asideActiveForcedKey.value && props.item.menuSecondaryKey && asideActiveForcedKey.value === props.item.menuSecondaryKey) {
        base.push('is-active')
      }

      return base
    })

    const componentActiveClass = computed(() => asideActiveForcedKey.value ? null : 'is-active')

    const menuClick = () => {
      emit('menu-click', props.item)

      if (hasDropdown.value) {
        isDropdownActive.value = !isDropdownActive.value

        if (!props.isSecondary) {
          store.commit('asideStateToggle', true)
        }
      }
    }

    watch(isAsideExpanded, newValue => {
      if (!newValue) {
        isDropdownActive.value = false
      }
    })

    return {
      componentIs,
      isDropdownActive,
      hasDropdown,
      dropdownIcon,
      hasSubmenuIcon,
      submenuIcon,
      componentTitle,
      componentClass,
      componentActiveClass,
      menuClick
    }
  }
}
</script>
