<template>
  <nav
    v-show="isNavBarVisible"
    id="navbar-main"
    class="navbar"
    :class="navBarClass"
  >
    <div class="navbar-brand no-negative-margin-left">
      <a
        v-if="$auth.isAuthenticated"
        :title="toggleTooltip"
        class="navbar-item is-desktop-icon-only"
        @click.prevent="asideToggle"
      >
        <b-icon :icon="asideToggleIcon" />
      </a>
    </div>
    <div
      v-if="isLayoutMobile"
      class="navbar-brand is-right"
    >
      <a
        class="navbar-item navbar-item-menu-toggle"
        @click.prevent="updatesToggle"
      >
        <b-icon
          icon="bell"
          custom-size="default"
        />
      </a>
      <a
        class="navbar-item navbar-item-menu-toggle"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon
          :icon="menuNavBarToggleIcon"
          custom-size="default"
        />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div
        v-if="$auth.isAuthenticated"
        class="navbar-end"
      >
        <nav-bar-menu
          v-if="$auth.isAuthenticated"
          class="has-divider has-user-avatar"
        >
          <div class="is-user-email">
            <span>{{ $auth.user.email }}</span>
          </div>
        </nav-bar-menu>
        <logout-button />
      </div>
      <div
        v-else
        class="navbar-end"
      >
        <login-button />
      </div>
    </div>
  </nav>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { useStore } from '@/store'
import { useRouter } from '@/router'
import NavBarMenu from '@/components/NavBarMenu.vue'
import LogoutButton from './LogoutButton.vue'
import LoginButton from './LoginButton.vue'

export default {
  name: 'NavBar',
  components: {
    NavBarMenu,
    LogoutButton,
    LoginButton
  },
  setup (props, { root: { $buefy } }) {
    const isMenuNavBarActive = ref(false)

    const menuNavBarToggleIcon = computed(() => isMenuNavBarActive.value ? 'close' : 'dots-vertical')

    const asideToggleIcon = computed(() => {
      if (isLayoutAsideHidden.value) {
        return isAsideVisible.value ? 'close' : 'menu'
      }

      const isExpanded = isLayoutMobile.value ? isAsideMobileExpanded.value : isAsideExpanded.value

      return isExpanded ? 'backburger' : 'forwardburger'
    })

    const toggleTooltip = computed(() => isAsideExpanded.value ? 'Collapse' : 'Expand')

    const navBarClass = computed(() => {
      const base = []

      if (!isLayoutBoxed.value) {
        base.push('is-fixed-top')
      }

      if (navBarColor.value) {
        base.push(navBarColor.value)
      }

      return base
    })

    const store = useStore()

    const isLayoutMobile = computed(() => store.state.isLayoutMobile)
    const isLayoutAsideHidden = computed(() => store.state.isLayoutAsideHidden)
    const isLayoutBoxed = computed(() => store.state.isLayoutBoxed)
    const isNavBarVisible = computed(() => store.state.isNavBarVisible)
    const navBarColor = computed(() => store.state.navBarColor)
    const isAsideVisible = computed(() => store.state.isAsideVisible)
    const isAsideExpanded = computed(() => store.state.isAsideExpanded)
    const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded)
    const isAsideRightActive = computed(() => store.state.isAsideRightActive)
    const userName = computed(() => store.state.userName)
    const hasUpdates = computed(() => store.state.hasUpdates)

    const router = useRouter()

    router.afterEach(() => {
      isMenuNavBarActive.value = false
    })

    const asideToggle = () => {
      if (isLayoutMobile.value) {
        store.commit('asideMobileStateToggle')
      } else if (isLayoutAsideHidden.value) {
        store.dispatch('asideVisibilityToggle')
      } else {
        store.commit('asideStateToggle')
      }
    }

    const menuNavBarToggle = () => {
      isMenuNavBarActive.value = !isMenuNavBarActive.value
    }

    const updatesToggle = () => {
      store.dispatch('asideRightToggle')
    }

    const logout = () => {
      $buefy.snackbar.open({
        message: 'Log out clicked',
        queue: false
      })
    }

    return {
      isMenuNavBarActive,
      menuNavBarToggleIcon,
      asideToggleIcon,
      toggleTooltip,
      navBarClass,
      isLayoutMobile,
      isLayoutAsideHidden,
      isLayoutBoxed,
      isNavBarVisible,
      navBarColor,
      isAsideVisible,
      isAsideExpanded,
      isAsideMobileExpanded,
      isAsideRightActive,
      userName,
      hasUpdates,
      asideToggle,
      menuNavBarToggle,
      updatesToggle,
      logout
    }
  }
}
</script>
