<template>
  <aside
    v-show="isAsideRightVisible || !isLayoutAsideHidden"
    class="aside is-placed-right"
  >
    <div
      ref="container"
      class="aside-container"
    />
  </aside>
</template>

<script>
import { useStore } from '@/store'
import { computed } from '@vue/composition-api'

export default {
  name: 'AsideRight',
  setup () {
    const store = useStore()

    const items = computed(() => store.state.updates)

    const isAsideRightVisible = computed(() => store.state.isAsideRightVisible)
    const isLayoutAsideHidden = computed(() => store.state.isLayoutAsideHidden)

    return {
      items,
      isAsideRightVisible,
      isLayoutAsideHidden
    }
  }
}
</script>
