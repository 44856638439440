<template>
  <aside
    v-show="isAsideVisible"
    class="aside is-placed-left"
    :class="{'is-expanded':isAsideExpanded || isSecondary, 'is-secondary':isSecondary}"
  >
    <aside-tools
      :has-close="isSecondary"
      :icon="icon"
      @close="close"
    >
      <span v-if="!isSecondary">
        <b>Linguapro</b>
      </span>
      <span v-else-if="label">{{ label }}</span>
    </aside-tools>
    <div class="menu-container">
      <div class="menu is-menu-main">
        <template v-for="(menuGroup, index) in menu">
          <p
            v-if="typeof menuGroup === 'string'"
            :key="index"
            class="menu-label"
          >
            {{ menuGroup }}
          </p>
          <aside-menu-list
            v-else
            :key="index"
            :is-secondary="isSecondary"
            :menu="menuGroup"
            @menu-click="menuClick"
          />
        </template>
      </div>
    </div>
    <div class="menu is-menu-bottom">
      <aside-menu-list
        :menu="menuBottom"
        @menu-click="menuClick"
      />
    </div>
  </aside>
</template>

<script>
import { useStore } from '@/store'
import { computed } from '@vue/composition-api'
import AsideTools from '@/components/AsideTools.vue'
import AsideMenuList from '@/components/AsideMenuList.vue'

export default {
  name: 'AsideMenu',
  components: { AsideTools, AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    menuBottom: {
      type: Array,
      default: () => []
    },
    isSecondary: Boolean,
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    }
  },
  emits: ['menu-click', 'close'],
  setup (props, { emit }) {
    const store = useStore()
    const isAsideVisible = computed(() => store.state.isAsideVisible)
    const isAsideExpanded = computed(() => store.state.isAsideExpanded)
    const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded)

    const menuClick = item => {
      emit('menu-click', item)
    }

    const close = () => {
      emit('close')
    }

    return {
      isAsideVisible,
      isAsideExpanded,
      isAsideMobileExpanded,
      menuClick,
      close
    }
  }
}
</script>
